// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-actualites-tsx": () => import("./../../../src/pages/actualites.tsx" /* webpackChunkName: "component---src-pages-actualites-tsx" */),
  "component---src-pages-concerts-tsx": () => import("./../../../src/pages/concerts.tsx" /* webpackChunkName: "component---src-pages-concerts-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-les-amis-tsx": () => import("./../../../src/pages/les-amis.tsx" /* webpackChunkName: "component---src-pages-les-amis-tsx" */),
  "component---src-pages-mentions-legales-tsx": () => import("./../../../src/pages/mentions-legales.tsx" /* webpackChunkName: "component---src-pages-mentions-legales-tsx" */),
  "component---src-pages-photos-tsx": () => import("./../../../src/pages/photos.tsx" /* webpackChunkName: "component---src-pages-photos-tsx" */),
  "component---src-pages-presse-tsx": () => import("./../../../src/pages/presse.tsx" /* webpackChunkName: "component---src-pages-presse-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

